import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "login-container" }
const _hoisted_2 = { class: "login-box" }
const _hoisted_3 = { class: "form-item" }
const _hoisted_4 = { class: "form-item" }

import { ref } from 'vue'
import { NInput, useMessage } from 'naive-ui'
import { login } from '@/api/session.api';
import { useAuthStore } from '@/store/auth.store';
import { useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginView',
  setup(__props) {

const router = useRouter()
const msg = useMessage()

const username = ref('')
const password = ref('')

const handleLogin = async () => {
  const response = await login({
    username: username.value,
    password: password.value
  })

  if (typeof response === 'number') {
    msg.error('login failed');
  } else {
    const authStore = useAuthStore()
    authStore.setToken(response.token, response.exp, response.username)
    router.push('/')
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("h2", null, "Monitor", -1)),
      _createElementVNode("form", {
        onSubmit: _withModifiers(handleLogin, ["prevent"])
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_unref(NInput), {
            value: username.value,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((username).value = $event)),
            placeholder: "username"
          }, null, 8, ["value"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(NInput), {
            type: "password",
            value: password.value,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event)),
            placeholder: "password"
          }, null, 8, ["value"])
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("button", {
          type: "submit",
          class: "login-btn"
        }, "Login", -1))
      ], 32)
    ])
  ]))
}
}

})