export const formatDateTime = (date: Date, fillZero = true) => {
    // YYYY-MM-dd HH:mm:ss in UTC
    const year = date.getUTCFullYear()
    const month = fillZero ? (date.getUTCMonth() + 1).toString().padStart(2, '0') : date.getUTCMonth() + 1
    const day = fillZero ? date.getUTCDate().toString().padStart(2, '0') : date.getUTCDate()
    const hour = fillZero ? date.getUTCHours().toString().padStart(2, '0') : date.getUTCHours()
    const minute = fillZero ? date.getUTCMinutes().toString().padStart(2, '0') : date.getUTCMinutes()
    const second = fillZero ? date.getUTCSeconds().toString().padStart(2, '0') : date.getUTCSeconds()

    return `${year}-${month}-${day} ${hour}:${minute}:${second}`
}