import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-11/12 h-[600px] mx-auto" }
const _hoisted_2 = { class: "w-11/12 h-[600px] mt-[200px] mx-auto" }
const _hoisted_3 = { class: "w-11/12 mt-[200px] mx-auto" }

import { useRoute } from 'vue-router';
import { nextTick, onMounted, ref, h } from 'vue';
import { ChartManager } from '@/utils/ChartManager';
import { DiskChartManager } from '@/utils/DiskChartManager';
import { CpuChartManager } from '@/utils/CpuChartManager';
import { DbProcessChartManager } from '@/utils/DbProcessChartManager';
import { 
  NDataTable, 
  type DataTableColumns, 
  NModal, 
  NForm, 
  NFormItem, 
  NInput, 
  NInputNumber, 
  NButton, 
  NTooltip, 
  NIcon, 
  NCard,
  NDynamicTags,
  NDynamicInput,
  NSwitch
 } from 'naive-ui';
import { addBackendProcess, deleteBackendProcess, fetchRunningBackendProcesses, restartBackendProcess, startBackendProcess, stopBackendProcess, updateBackendProcess } from '@/api/detail.api';
import { HelpCircleOutline, CheckmarkCircleOutline, CloseCircleOutline } from '@vicons/ionicons5';
import { formatDateTime } from '@/utils/time.utils';
import { getLastDbProcessList } from '@/api/system-data.api';

type RunningBackendProcess = Awaited<ReturnType<typeof fetchRunningBackendProcesses>>[number]

export default /*@__PURE__*/_defineComponent({
  __name: 'DetailView',
  setup(__props) {

const route = useRoute()
const id = route.params.id
const isUpdate = ref(false)
const chartManager = ref<ChartManager>()
const diskChartManager = ref<DiskChartManager>()
const cpuChartManager = ref<CpuChartManager>()
const dbProcessChartManager = ref<DbProcessChartManager>()

// dynamic input 接收的是 hash array, 这里单独用一个变量接收，发送的时候转换赋值给newBackendProcess.envs
const envList = ref([
  {key: '', value: ''}
]);

const showAddBackendProcessModal = ref(false)
const newBackendProcess = ref<Omit<RunningBackendProcess, 'running' | 'last_error' | 'last_exit_code' | 'last_restart_at' | 'created_at' | 'restart_count'>>({
  id: 0,
  host_name: id as string,
  process_name: '',
  program: '',
  args: [],
  auto_restart: true,
  max_restarts: 0,
  restart_delay: 0,
  envs: {},
  user: '',
  cwd: '',
  dependencies: [],
  exponential_backoff: false,
})

const columns = ref<DataTableColumns>([
  { title: 'ID', key: 'id', width: 48, fixed: 'left' },
  { title: '任务名称', key: 'process_name', width: 128, fixed: 'left' },
  // { title: '任务描述', key: 'description' },
  { 
    title: '运行状态', 
    key: 'running',
    width: 96,
    fixed: 'left',
    render(row) {
      return row.running ? 
        h(NIcon, { color: '#18a058', size: 24 }, { default: () => h(CheckmarkCircleOutline) }) : 
        h(NIcon, { color: '#d03050', size: 24 }, { default: () => h(CloseCircleOutline) });
    }
  },
  { title: '重启次数', key: 'restart_count', width: 96, fixed: 'left' },
  { title: '最后退出码', key: 'last_exit_code', width: 96 },
  { title: '最后错误', key: 'last_error', width: 96 },
  { title: '最后重启时间', key: 'last_restart_at', width: 176, render(row) {
    if (!row.last_restart_at) {
      return '无'
    }
    return formatDateTime(new Date(row.last_restart_at as string))
  } },
  { title: '程序', key: 'program', width: 256 },
  { title: '参数',
    key: 'args',
    width: 256,
    render(row) {
      return (row.args as string[]).join(', ')
    }
  },
  { 
    title: '自动重启', 
    key: 'auto_restart',
    width: 96,
    render(row) {
      return row.auto_restart ? 
        h(NIcon, { color: '#18a058', size: 24 }, { default: () => h(CheckmarkCircleOutline) }) : 
        h(NIcon, { color: '#d03050', size: 24 }, { default: () => h(CloseCircleOutline) });
    }
  },
  { title: '最大重启次数', key: 'max_restarts', width: 120 },
  { title: '重启延迟', key: 'restart_delay', width: 96 },
  { 
    title: '环境变量', 
    key: 'envs',
    width: 256,
    render(row) {
      if (!row.envs || Object.keys(row.envs).length === 0) {
        return null;
      }
      return h('div', {
        style: {
          whiteSpace: 'pre-line'  // ���持换行
        }
      }, Object.entries(row.envs)
          .map(([key, value]) => `${key}=${value}`)
          .join('\n')
      );
    }
  },
  { title: '用户', key: 'user', width: 96 },
  { 
    title: '指数回避', 
    key: 'exponential_backoff',
    width: 96,
    render(row) {
      return row.exponential_backoff ? 
        h(NIcon, { color: '#18a058', size: 24 }, { default: () => h(CheckmarkCircleOutline) }) : 
        h(NIcon, { color: '#d03050', size: 24 }, { default: () => h(CloseCircleOutline) });
    }
  },
  { title: '工作目录', key: 'cwd', width: 256 },
  { 
    title: '依赖', 
    key: 'dependencies',
    width: 256,
    render(row) {
      if (!row.dependencies || (row.dependencies as string[]).length === 0) {
        return '无';
      }
      return (row.dependencies as string[]).join(', ')
    }
  },
  { title: '创建时间', key: 'created_at', width: 176, render(row) {
    return formatDateTime(new Date(row.created_at as string))
  } },
  // 3个按钮：停止、删除、重启
  { 
    title: '操作', 
    key: 'actions', 
    width: 280, 
    fixed: 'right', 
    render(row) {
      return h('div', { class: 'space-x-2' }, [
        h(
          NButton,
          { 
            type: row.running ? 'warning' : 'primary',
            size: 'small',
            onClick: async () => {
              if (row.running) {
                await stopBackendProcess(id as string, row.process_name as string)
              } else {
                await startBackendProcess(id as string, row.process_name as string)
              }
              data.value = await fetchRunningBackendProcesses(id as string)
            }
          },
          { default: () => row.running ? '停止' : '启动' }
        ),
        h(
          NButton,
          { 
            type: 'warning',
            size: 'small',
            onClick: async () => {
              await restartBackendProcess(id as string, row.process_name as string)
              data.value = await fetchRunningBackendProcesses(id as string)
            }
          },
          { default: () => '重启' }
        ),
        h(
          NButton,
          { 
            type: 'error',
            size: 'small',
            onClick: async () => {
              await deleteBackendProcess(id as string, row.process_name as string, row.id as number)
              data.value = await fetchRunningBackendProcesses(id as string)
            }
          },
          { default: () => '删除' }
        ),
        h(
          NButton,
          {
            type: 'info',
            size: 'small',
            onClick: () => {
              isUpdate.value = true
              newBackendProcess.value = row as any;
              showAddBackendProcessModal.value = true
              envList.value = Object.entries(row.envs as Record<string, string>).map(([key, value]) => ({key, value}))
            }
          },
          { default: () => '编辑' }
        )
      ])
    }
  },
])

const data = ref<Awaited<ReturnType<typeof fetchRunningBackendProcesses>>>([])

const dblistColumns = ref<DataTableColumns>([
  { title: 'ID', key: 'id', width: 48, fixed: 'left' },
  { title: '用户', key: 'user', width: 96 },
  { title: '主机', key: 'host', width: 96 },
  { title: '数据库', key: 'db', width: 96 },
  { title: '命令', key: 'command', width: 256 },
  { title: '时间', key: 'time', width: 96 },
  { title: '状态', key: 'state', width: 96 },
  { title: '信息', key: 'info', width: 256 },
])

const dblistData = ref<Awaited<ReturnType<typeof getLastDbProcessList>>>([])

const loadingDbList = ref(false)
const refreshDbProcessList = async () => {
  loadingDbList.value = true
  try {
    dblistData.value = await getLastDbProcessList(id as string)
  } catch (error) {
    console.error(error)
  } finally {
    loadingDbList.value = false
  }
}

onMounted(async () => {
  await nextTick()

  chartManager.value = new ChartManager('memory-chart', id as string)
  await chartManager.value.initLoad()

  diskChartManager.value = new DiskChartManager('disk-chart', id as string)
  await diskChartManager.value.initLoad()

  cpuChartManager.value = new CpuChartManager('cpu-chart', id as string)
  await cpuChartManager.value.initLoad()

  dbProcessChartManager.value = new DbProcessChartManager('db-process-chart', id as string)
  await dbProcessChartManager.value.initLoad()

  data.value = await fetchRunningBackendProcesses(id as string)
  dblistData.value = await getLastDbProcessList(id as string)
})

const loading = ref(false)
const refreshBackendProcesses = async () => {
  loading.value = true
  try {
    data.value = await fetchRunningBackendProcesses(id as string)
  } catch (error) {
    console.error(error)
  } finally {
    loading.value = false
  }
}

const addProcess = async () => {
  try {
    if (isUpdate.value) {
      await editProcess()
    } else {
      newBackendProcess.value.envs = envList.value.reduce((acc, cur) => {
        acc[cur.key] = cur.value
        return acc
      }, {} as Record<string, string>)
      console.log(newBackendProcess.value)

      await addBackendProcess(newBackendProcess.value)
      data.value = await fetchRunningBackendProcesses(id as string)
    }
  } catch (error) {
    console.error(error)
  } finally {
    envList.value = [{key: '', value: ''}]
    isUpdate.value = false
    showAddBackendProcessModal.value = false
  }
}

const editProcess = async () => {
  newBackendProcess.value.envs = envList.value.reduce((acc, cur) => {
    acc[cur.key] = cur.value
    return acc
  }, {} as Record<string, string>)
  await updateBackendProcess(newBackendProcess.value)
  data.value = await fetchRunningBackendProcesses(id as string)
}

const cancel = () => {
  isUpdate.value = false
  newBackendProcess.value = {
    id: 0,
    host_name: id as string,
    process_name: '',
    program: '',
    args: [],
    auto_restart: true,
    max_restarts: 0,
    restart_delay: 0,
    envs: {},
    user: '',
    cwd: '',
    dependencies: [],
    exponential_backoff: false,
  }
  showAddBackendProcessModal.value = false
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", null, "hostname: " + _toDisplayString(_unref(id)), 1),
      _cache[13] || (_cache[13] = _createElementVNode("h2", null, "内存数据", -1)),
      _cache[14] || (_cache[14] = _createElementVNode("div", {
        id: "memory-chart",
        class: "w-full h-full"
      }, null, -1))
    ]),
    _cache[24] || (_cache[24] = _createStaticVNode("<div class=\"w-11/12 h-[600px] mt-[200px] mx-auto\"><h2>磁盘数据</h2><div id=\"disk-chart\" class=\"w-full h-full\"></div></div><div class=\"w-11/12 h-[600px] mt-[200px] mx-auto\"><h2>CPU负载</h2><div id=\"cpu-chart\" class=\"w-full h-full\"></div></div><div class=\"w-11/12 h-[600px] mt-[200px] mx-auto\"><h2>数据库进程</h2><div id=\"db-process-chart\" class=\"w-full h-full\"></div></div>", 3)),
    _createElementVNode("div", _hoisted_2, [
      _cache[16] || (_cache[16] = _createElementVNode("h2", null, "数据库进程列表", -1)),
      _createVNode(_unref(NButton), {
        type: "primary",
        class: "mb-4",
        loading: loadingDbList.value,
        onClick: refreshDbProcessList
      }, {
        default: _withCtx(() => _cache[15] || (_cache[15] = [
          _createTextVNode("刷新")
        ])),
        _: 1
      }, 8, ["loading"]),
      _createVNode(_unref(NDataTable), {
        class: "mb-24",
        columns: dblistColumns.value,
        data: dblistData.value,
        "min-height": 400,
        "scroll-x": 1360,
        "row-height": 48,
        "header-height": 48,
        bordered: false,
        "single-line": false
      }, null, 8, ["columns", "data"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[23] || (_cache[23] = _createElementVNode("h2", null, "后台任务", -1)),
      _createVNode(_unref(NButton), {
        class: "mb-4",
        type: "primary",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (showAddBackendProcessModal.value = true))
      }, {
        default: _withCtx(() => _cache[17] || (_cache[17] = [
          _createTextVNode("添加后台任务")
        ])),
        _: 1
      }),
      _createVNode(_unref(NButton), {
        class: "mb-4 ml-4",
        loading: loading.value,
        type: "info",
        onClick: refreshBackendProcesses
      }, {
        default: _withCtx(() => _cache[18] || (_cache[18] = [
          _createTextVNode("刷新任务状态")
        ])),
        _: 1
      }, 8, ["loading"]),
      _createVNode(_unref(NModal), {
        show: showAddBackendProcessModal.value,
        "onUpdate:show": _cache[12] || (_cache[12] = ($event: any) => ((showAddBackendProcessModal).value = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(NCard), {
            style: {"width":"600px"},
            title: "添加后台任务",
            bordered: false,
            size: "huge"
          }, {
            footer: _withCtx(() => [
              _createVNode(_unref(NButton), {
                type: "primary",
                onClick: addProcess,
                class: "mr-4"
              }, {
                default: _withCtx(() => _cache[19] || (_cache[19] = [
                  _createTextVNode("添加")
                ])),
                _: 1
              }),
              _createVNode(_unref(NButton), { onClick: cancel }, {
                default: _withCtx(() => _cache[20] || (_cache[20] = [
                  _createTextVNode("取消")
                ])),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createVNode(_unref(NForm), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(NFormItem), {
                    label: "任务名称",
                    required: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(NInput), {
                        value: newBackendProcess.value.process_name,
                        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((newBackendProcess.value.process_name) = $event))
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(NFormItem), { label: "可执行程序" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(NInput), {
                        value: newBackendProcess.value.program,
                        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((newBackendProcess.value.program) = $event)),
                        placeholder: "请输入可执行程序路径, 例如 /usr/bin/perl"
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(NFormItem), { label: "参数" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(NTooltip), null, {
                        trigger: _withCtx(() => [
                          _createVNode(_unref(NIcon), { component: _unref(HelpCircleOutline) }, null, 8, ["component"])
                        ]),
                        default: _withCtx(() => [
                          _cache[21] || (_cache[21] = _createElementVNode("div", null, "参数，务必看好顺序", -1))
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(NDynamicTags), {
                        value: newBackendProcess.value.args,
                        "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((newBackendProcess.value.args) = $event))
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(NFormItem), { label: "环境变量" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(NDynamicInput), {
                        value: envList.value,
                        "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((envList).value = $event)),
                        preset: "pair",
                        "key-placeholder": "环境变量名",
                        "value-placeholder": "环境变量值"
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(NFormItem), { label: "工作目录" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(NInput), {
                        value: newBackendProcess.value.cwd,
                        "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((newBackendProcess.value.cwd) = $event)),
                        placeholder: "请输入工作目录, 例如 /home/user"
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(NFormItem), {
                    label: "用户",
                    required: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(NInput), {
                        value: newBackendProcess.value.user,
                        "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((newBackendProcess.value.user) = $event)),
                        placeholder: "请输入用户名, 例如 root"
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(NFormItem), { label: "是否自动重启" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(NSwitch), {
                        value: newBackendProcess.value.auto_restart,
                        "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((newBackendProcess.value.auto_restart) = $event))
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }),
                  (newBackendProcess.value.auto_restart)
                    ? (_openBlock(), _createBlock(_unref(NFormItem), {
                        key: 0,
                        label: "最大重启次数"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(NTooltip), null, {
                            trigger: _withCtx(() => [
                              _createVNode(_unref(NIcon), { component: _unref(HelpCircleOutline) }, null, 8, ["component"])
                            ]),
                            default: _withCtx(() => [
                              _cache[22] || (_cache[22] = _createElementVNode("div", null, "最大重启次数，超过此次数后不再自动重启，0表示不重启, -1表示不限制次数", -1))
                            ]),
                            _: 1
                          }),
                          _createVNode(_unref(NInputNumber), {
                            value: newBackendProcess.value.max_restarts,
                            "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((newBackendProcess.value.max_restarts) = $event))
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (newBackendProcess.value.auto_restart)
                    ? (_openBlock(), _createBlock(_unref(NFormItem), {
                        key: 1,
                        label: "重启延迟"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(NInputNumber), {
                            value: newBackendProcess.value.restart_delay,
                            "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((newBackendProcess.value.restart_delay) = $event))
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (newBackendProcess.value.auto_restart && newBackendProcess.value.restart_delay > 0)
                    ? (_openBlock(), _createBlock(_unref(NFormItem), {
                        key: 2,
                        label: "是否开启指数回避"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(NSwitch), {
                            value: newBackendProcess.value.exponential_backoff,
                            "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((newBackendProcess.value.exponential_backoff) = $event))
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_unref(NFormItem), { label: "依赖" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(NDynamicTags), {
                        value: newBackendProcess.value.dependencies,
                        "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((newBackendProcess.value.dependencies) = $event))
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["show"]),
      _createVNode(_unref(NDataTable), {
        class: "mb-24",
        columns: columns.value,
        data: data.value,
        "min-height": 400,
        "scroll-x": 1360,
        "row-height": 48,
        "header-height": 48,
        bordered: false,
        "single-line": false
      }, null, 8, ["columns", "data"])
    ])
  ], 64))
}
}

})