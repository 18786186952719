// 获取当前网站的协议和host
const protocol = window.location.protocol
const host = window.location.host

// API基础URL
export const API_BASE_URL = `${protocol}//${host}/api`

// dev地址 localhost:7667/api
export const DEV_API_BASE_URL = 'http://localhost:7667/api'

export const getApiBaseUrl = () => {
    return process.env.NODE_ENV === 'development' ? DEV_API_BASE_URL : API_BASE_URL
}
