import * as echarts from 'echarts'
import type { EChartsOption } from 'echarts'
import { fetchDbProcesses } from "@/api/detail.api"
import { formatDateTime } from './time.utils'
import { debounce, type DebouncedFunc } from 'lodash'

export class DbProcessChartManager {
    private chart: echarts.ECharts
    private loading: boolean
    private hostName: string
    private currentData: [number, number][] = []
    private currentRange = {
        start: new Date(),
        end: new Date()
    }
    private debouncedLoadData: DebouncedFunc<(start: Date, end: Date) => void>

    constructor(elementId: string, hostname: string) {
        const element = document.getElementById(elementId)
        if (!element) throw new Error('element not found')
        
        this.chart = echarts.init(element)
        this.loading = false
        this.hostName = hostname
        // 创建防抖的loadData函数，延迟500ms
        this.debouncedLoadData = debounce(this.loadData.bind(this), 500)
        this.initChart()
    }

    private initChart() {
        const option: EChartsOption = {
            tooltip: {
                trigger: 'axis',
                formatter: (params: any) => {
                    const time = new Date(params[0].value[0]).toUTCString()
                    return `${time}<br/>值: ${params[0].value[1]}`
                }
            },
            xAxis: {
                type: 'time',
                splitLine: {
                    show: false,
                }
            },
            yAxis: {
                type: 'value',
                name: '进程数量',
                splitLine: {
                    show: true
                },
                axisLabel: {
                    formatter: '{value}',
                }
            },
            dataZoom: [
                {
                    type: 'inside',
                    start: 0,
                    end: 100,
                    minSpan: 1,  // 最小缩放比例为1%
                },
                {
                    type: 'slider',
                    start: 0,
                    end: 100,
                    minSpan: 1,  // 最小缩放比例为1%
                }
            ],
            series: [{
                name: '进程数量',
                type: 'line',
                showSymbol: false,
                data: []
            }]
        }

        this.chart.setOption(option)

        // 监听缩放事件
        this.chart.on('datazoom', (params: any) => {
            if (this.loading || !this.chart || !this.currentData.length) return

            console.log('DataZoom triggered:', params)

            // 获取当前数据的时间范围
            const dataTimeRange = this.getDataTimeRange()
            
            // 从当前的 option 中获取缩放范围
            const option = this.chart.getOption()
            const dataZoom = option.dataZoom as any[]
            if (!dataZoom || !dataZoom[0]) {
                console.warn('No dataZoom found in options')
                return
            }

            const { start, end } = dataZoom[0]
            if (typeof start !== 'number' || typeof end !== 'number') {
                console.warn('Invalid zoom range:', { start, end })
                return
            }

            // 计算实际的时间戳
            const timeRange = dataTimeRange.end - dataTimeRange.start
            const startTime = dataTimeRange.start + (timeRange * start / 100)
            const endTime = dataTimeRange.start + (timeRange * end / 100)

            console.log('Calculated time range:', {
                start: new Date(startTime).toUTCString(),
                end: new Date(endTime).toUTCString(),
                percentRange: { start, end }
            })

            // 检查是否需要加载新数据
            if (this.shouldLoadNewData(startTime, endTime)) {
                this.debouncedLoadData(new Date(startTime), new Date(endTime))
            } else {
                // 如果不需要加载新数据，只更新显示范围
                this.updateVisibleData(startTime, endTime)
            }
        })
    }

    private getDataTimeRange() {
        if (!this.currentData.length) {
            return {
                start: Date.now(),
                end: Date.now()
            };
        }
        return {
            start: this.currentData[0][0],
            end: this.currentData[this.currentData.length - 1][0]
        };
    }

    private calculateZoomedTimeRange(startPercent: number, endPercent: number, dataRange: { start: number, end: number }) {
        const totalRange = dataRange.end - dataRange.start;
        const start = dataRange.start + (totalRange * startPercent / 100);
        const end = dataRange.start + (totalRange * endPercent / 100);
        return { start, end };
    }

    private updateVisibleData(startTime: number, endTime: number) {
        if (!this.chart) return

        const visibleData = this.currentData.filter(point =>
            point[0] >= startTime && point[0] <= endTime
        );

        console.log('Updating visible data:', {
            totalPoints: this.currentData.length,
            visiblePoints: visibleData.length,
            timeRange: {
                start: new Date(startTime).toUTCString(),
                end: new Date(endTime).toUTCString()
            }
        });

        this.chart.setOption({
            series: [{
                data: visibleData
            }]
        });
    }

    private shouldLoadNewData(startTime: number, endTime: number): boolean {
        const currentStart = this.currentRange.start.getTime();
        const currentEnd = this.currentRange.end.getTime();

        // 如果请求的时间范围完全在当前数据范围内，则不需要加载新数据
        if (startTime >= currentStart && endTime <= currentEnd) {
            return false;
        }

        return true;
    }

    private updateChartData(data: [number, number][]) {
        if (!this.chart) return

        console.log('Updating chart data:', {
            dataPoints: data.length,
            timeRange: {
                start: new Date(data[0]?.[0]).toUTCString(),
                end: new Date(data[data.length - 1]?.[0]).toUTCString()
            }
        });

        const option = this.chart.getOption();
        this.chart.setOption({
            ...option,
            series: [{
                ...(option.series as any)[0],
                data: data
            }]
        });
    }

    private calculateInterval(start: Date, end: Date): string {
        const hours = (end.getTime() - start.getTime()) / (1000 * 60 * 60);
        if (hours > 24 * 7) return '1hour';  // 超过一周用小时
        if (hours > 24) return '30min';      // 超过一天用30分钟
        if (hours > 6) return '5min';        // 超过6小时用5分钟
        if (hours > 1) return '1min';        // 超过1小时用1分钟
        return '5sec';                       // 小于1小时用5秒
    }

    private async loadData(start: Date, end: Date) {
        if (this.loading || !start || !end || isNaN(start.getTime()) || isNaN(end.getTime())) {
            console.warn('Invalid date range or loading in progress:', { start, end, loading: this.loading })
            return
        }

        try {
            this.loading = true
            if (this.chart) {
                this.chart.showLoading()
            }

            const interval = this.calculateInterval(start, end)
            console.log('Loading data with params:', {
                startTime: start.toUTCString(),
                endTime: end.toUTCString(),
                interval,
                hostName: this.hostName
            })

            const response = await fetchDbProcesses(
                this.hostName, 
                formatDateTime(start), 
                formatDateTime(end), 
                interval
            )

            const newData = response.map(item => [
                new Date(item.timestamp).getTime(),
                item.value,
            ] as [number, number])

            // 合并数据并去重
            const allData = [...this.currentData, ...newData]
            this.currentData = Array.from(new Map(allData.map(item => [item[0], item])).values())
                .sort((a, b) => a[0] - b[0])

            this.updateChartData(this.currentData)
            this.currentRange = { start, end }

            // 更新缩放范围
            if (this.chart) {
                const option = this.chart.getOption()
                const dataZoom = option.dataZoom as any[]
                if (dataZoom) {
                    const startTime = start.getTime()
                    const endTime = end.getTime()
                    dataZoom.forEach(zoom => {
                        if (zoom.type === 'slider' || zoom.type === 'inside') {
                            zoom.startValue = startTime
                            zoom.endValue = endTime
                        }
                    })
                    this.chart.setOption({ dataZoom })
                }
            }
        } catch (error) {
            console.error('Error loading data:', error)
        } finally {
            this.loading = false
            if (this.chart) {
                this.chart.hideLoading()
            }
        }
    }

    async initLoad() {
        const end = new Date();
        // 默认加载最近24小时的数据
        const start = new Date(end.getTime() - 24 * 60 * 60 * 1000);
        await this.loadData(start, end);
    }

    resize() {
        if (this.chart) {
            this.chart.resize()
        }
    }

    dispose() {
        // 清理防抖函数
        if (this.debouncedLoadData) {
            this.debouncedLoadData.cancel()
        }
        if (this.chart) {
            this.chart.dispose()
            this.chart = null as any
        }
    }
}