<template>
  <div class="login-container">
    <div class="login-box">
      <h2>Monitor</h2>
      <form @submit.prevent="handleLogin">
        <div class="form-item">
            <n-input v-model:value="username" placeholder="username" />
        </div>
        <div class="form-item">
          <n-input type="password" v-model:value="password" placeholder="password" />
        </div>
        <button type="submit" class="login-btn">Login</button>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { NInput, useMessage } from 'naive-ui'
import { login } from '@/api/session.api';
import { useAuthStore } from '@/store/auth.store';
import { useRouter } from 'vue-router';

const router = useRouter()
const msg = useMessage()

const username = ref('')
const password = ref('')

const handleLogin = async () => {
  const response = await login({
    username: username.value,
    password: password.value
  })

  if (typeof response === 'number') {
    msg.error('login failed');
  } else {
    const authStore = useAuthStore()
    authStore.setToken(response.token, response.exp, response.username)
    router.push('/')
  }
}
</script>

<style scoped lang="scss">
.login-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff no-repeat center center;
  background-size: cover;
}

.login-box {
  padding: 40px;
  width: 400px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);

  h2 {
    text-align: center;
    color: #333;
    margin-bottom: 30px;
  }

  .form-item {
    margin-bottom: 20px;

    input {
      width: 100%;
      padding: 12px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(5px);
      color: #333;
      font-size: 16px;
      outline: none;
      transition: all 0.3s ease;

      &::placeholder {
        color: #666;
      }

      &:focus {
        border-color: rgba(255, 255, 255, 0.5);
        background: rgba(255, 255, 255, 0.2);
      }
    }
  }

  .login-btn {
    width: 100%;
    padding: 12px;
    background: rgba(51, 51, 51, 0.9);
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background: rgba(51, 51, 51, 1);
      transform: translateY(-2px);
    }
  }
}
</style>