<template>
    <n-grid :cols="24" :x-gap="16" :y-gap="16">
        <n-grid-item :span="deviceColumns" v-for="item in systemData" :key="item.id">
            <n-card :title="item.host_name" hoverable>
                <!-- <template #cover> -->
                    <!-- <img :src="item.imageUrl" v-if="item.imageUrl" /> -->
                <!-- </template> -->
                <n-space vertical>
                    <span>统计</span>
                    <div class="flex flex-col items-center gap-4">
                        <div>
                            <h2>Memory</h2>
                            <n-tooltip>
                                <template #trigger>
                                    <n-progress type="circle" :percentage="formatPercentage(item.data.memory.used, item.data.memory.total)" />
                                </template>
                                total: {{ (item.data.memory.total / 1024 / 1024).toFixed(2) }} MB used: {{ (item.data.memory.used / 1024 / 1024).toFixed(2) }} MB available: {{ (item.data.memory.available / 1024 / 1024).toFixed(2) }} MB
                            </n-tooltip>
                        </div>
                        <div v-for="disk in item.data.disks" :key="disk.name">
                            <h2>Disk {{ disk.name }}</h2>
                            <n-tooltip>
                                <template #trigger>
                                    <n-progress :percentage="formatPercentage(disk.used_space, disk.total_space)" />
                                </template>
                                total: {{ (disk.total_space / 1024 / 1024 / 1024).toFixed(2) }} GB used: {{ (disk.used_space / 1024 / 1024 / 1024).toFixed(2) }} GB available: {{ (disk.available_space / 1024 / 1024 / 1024).toFixed(2) }} GB
                                mounted: {{ disk.mount_point }}
                            </n-tooltip>
                        </div>
                        <div>
                            <h2>Cpu</h2>
                            <p>CPU数量: {{ item.data.cpu.length }}</p>
                            <p>CPU使用率超过50%的CPU数量: {{ item.data.cpu.filter((cpu: any) => cpu.usage > 50).length }}</p>
                        </div>
                        <div>
                            <h2>数据库</h2>
                            <p>进程数量: {{ item.data.db_processlist.length }}</p>
                        </div>
                    </div>
                    <n-space justify="end">
                        <n-button type="primary" @click="handleViewDetail(item)">
                            查看详情
                        </n-button>
                    </n-space>
                </n-space>
            </n-card>
        </n-grid-item>
    </n-grid>
</template>

<script setup lang="ts">
import { getIndexSystemData, type SystemData } from '@/api/system-data.api'
import { ref, onMounted, computed } from 'vue'
import { useWindowSize } from '@vueuse/core'
import { NGrid, NGridItem, NCard, NSpace, NButton, NTooltip, NProgress } from 'naive-ui'
import router from "@/router";

const systemData = ref<SystemData[]>([])
const { width } = useWindowSize()

// 根据窗口宽度计算每个卡片应该占据的列数
const deviceColumns = computed(() => {
    if (width.value < 640) return 24        // 小屏幕一行显示1个
    if (width.value < 1024) return 12       // 中等屏幕一行显示2个
    if (width.value < 1280) return 8        // 大屏幕一行显示3个
    return 6                                // 超大屏幕一行显示4个
})

const handleViewDetail = (item: SystemData) => {
    router.push(`/detail/${item.host_name}`)
}

const formatPercentage = (value: number, total: number) => {
    const percentage = value / total * 100
    return parseFloat(percentage.toFixed(2))
}

onMounted(async () => {
    const data = await getIndexSystemData()
    systemData.value = data
})
</script>

<style scoped>
img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}
</style>