import * as echarts from 'echarts'
import { debounce, type DebouncedFunc } from 'lodash'
import { fetchCpuMetrics } from '../api/detail.api'
import { formatDateTime } from './time.utils'

interface CpuData {
    name: string
    data: [number, number][]  // [timestamp, value]
}

export class CpuChartManager {
    private currentData: Map<string, CpuData> = new Map()
    private loading = false
    private chart: echarts.ECharts | null = null
    private readonly elementId: string
    private readonly hostName: string
    private debouncedLoadData: DebouncedFunc<(start: Date, end: Date) => Promise<void>>
    private currentRange: { start: Date; end: Date } = {
        start: new Date(),
        end: new Date()
    }

    constructor(elementId: string, hostName: string) {
        this.elementId = elementId
        this.hostName = hostName
        this.debouncedLoadData = debounce(this.loadData.bind(this), 500)
    }

    private calculateInterval(start: Date, end: Date): string {
        const diff = end.getTime() - start.getTime()
        const hours = diff / (1000 * 60 * 60)
        
        if (hours <= 2) return '1m'
        if (hours <= 6) return '5m'
        if (hours <= 24) return '15m'
        if (hours <= 72) return '30m'
        return '1h'
    }

    async initLoad() {
        const container = document.getElementById(this.elementId)
        if (!container) {
            console.error(`Container with id ${this.elementId} not found`)
            return
        }

        this.initChart(container)
        const now = new Date()
        const oneHourAgo = new Date(now.getTime() - 60 * 60 * 1000)
        await this.loadData(oneHourAgo, now)
    }

    private initChart(container: HTMLElement) {
        if (this.chart) {
            this.chart.dispose()
        }

        this.chart = echarts.init(container)
        this.chart.setOption({
            title: {
                text: 'CPU 负载趋势',
                left: 'center'
            },
            tooltip: {
                trigger: 'axis',
                formatter: (params: any[]) => {
                    const time = new Date(params[0].value[0]).toLocaleString()
                    let result = `${time}<br/>`
                    params.forEach(param => {
                        result += `${param.seriesName}: ${param.value[1].toFixed(2)}<br/>`
                    })
                    return result
                }
            },
            legend: {
                data: ['1分钟平均负载', '5分钟平均负载', '15分钟平均负载'],
                top: 30
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                top: '15%',
                containLabel: true
            },
            xAxis: {
                type: 'time',
                splitLine: {
                    show: false,
                }
            },
            yAxis: {
                type: 'value',
                name: 'CPU负载',
                splitNumber: 6,
                splitLine: {
                    show: true
                },
                axisLabel: {
                    formatter: '{value}'
                }
            },
            series: [],
            dataZoom: [{
                type: 'inside',
                start: 0,
                end: 100
            }, {
                start: 0,
                end: 100
            }]
        })

        // 监听缩放事件
        this.chart.on('datazoom', (params: any) => {
            if (this.loading || !this.chart || this.currentData.size === 0) return

            console.log('DataZoom triggered:', params)

            // 获取当前数据的时间范围
            const dataTimeRange = this.getDataTimeRange()
            
            // 从当前的 option 中获取缩放范围
            const option = this.chart.getOption()
            const dataZoom = option.dataZoom as any[]
            if (!dataZoom || !dataZoom[0]) {
                console.warn('No dataZoom found in options')
                return
            }

            const { start, end } = dataZoom[0]
            if (typeof start !== 'number' || typeof end !== 'number') {
                console.warn('Invalid zoom range:', { start, end })
                return
            }

            // 计算实际的时间戳
            const timeRange = dataTimeRange.end - dataTimeRange.start
            const startTime = dataTimeRange.start + (timeRange * start / 100)
            const endTime = dataTimeRange.start + (timeRange * end / 100)

            console.log('Calculated time range:', {
                start: new Date(startTime).toISOString(),
                end: new Date(endTime).toISOString()
            })

            // 检查是否需要加载新数据
            if (this.shouldLoadNewData(startTime, endTime)) {
                this.debouncedLoadData(new Date(startTime), new Date(endTime))
            } else {
                // 如果不需要加载新数据，只更新显示范围
                this.updateVisibleData(startTime, endTime)
            }
        })

        window.addEventListener('resize', debounce(() => {
            this.chart?.resize()
        }, 300))
    }

    private shouldLoadNewData(startTime: number, endTime: number): boolean {
        const currentStart = this.currentRange.start.getTime()
        const currentEnd = this.currentRange.end.getTime()

        // 如果请求的时间范围完全在当前数据范围内，则不需要加载新数据
        if (startTime >= currentStart && endTime <= currentEnd) {
            return false
        }

        return true
    }

    private getDataTimeRange() {
        // 找到所有数据中的最早和最晚时间
        let start = Date.now()
        let end = 0

        for (const cpuData of this.currentData.values()) {
            if (cpuData.data.length > 0) {
                start = Math.min(start, cpuData.data[0][0])
                end = Math.max(end, cpuData.data[cpuData.data.length - 1][0])
            }
        }

        return { start, end }
    }

    async loadData(start: Date, end: Date) {
        if (this.loading || !start || !end || isNaN(start.getTime()) || isNaN(end.getTime())) {
            console.warn('Invalid date range or loading in progress:', { start, end, loading: this.loading })
            return
        }

        try {
            this.loading = true
            if (this.chart) {
                this.chart.showLoading()
            }

            const interval = this.calculateInterval(start, end)
            console.log('Loading CPU data with params:', {
                startTime: start.toUTCString(),
                endTime: end.toUTCString(),
                interval,
                hostName: this.hostName
            })

            const response = await fetchCpuMetrics(
                this.hostName,
                formatDateTime(start),
                formatDateTime(end),
                interval
            )

            console.log('Received CPU data:', response)

            // 初始化三个负载数据系列
            const oneMinData: CpuData = { name: '1分钟平均负载', data: [] }
            const fiveMinData: CpuData = { name: '5分钟平均负载', data: [] }
            const fifteenMinData: CpuData = { name: '15分钟平均负载', data: [] }

            // 处理数据
            response.forEach(item => {
                const timestamp = new Date(item.timestamp).getTime()
                oneMinData.data.push([timestamp, item.one_minute])
                fiveMinData.data.push([timestamp, item.five_minutes])
                fifteenMinData.data.push([timestamp, item.fifteen_minutes])
            })

            // 更新数据
            this.currentData.set('one_min', oneMinData)
            this.currentData.set('five_min', fiveMinData)
            this.currentData.set('fifteen_min', fifteenMinData)

            this.updateVisibleData(start.getTime(), end.getTime())
            this.currentRange = { start, end }
        } catch (error) {
            console.error('Error loading CPU data:', error)
        } finally {
            this.loading = false
            if (this.chart) {
                this.chart.hideLoading()
            }
        }
    }

    private updateVisibleData(startTime: number, endTime: number) {
        if (!this.chart) return

        const series: any[] = []
        const colors = ['#5470c6', '#91cc75', '#fac858']  // 为三种负载设置不同的颜色
        let index = 0

        this.currentData.forEach((cpuData) => {
            const visibleData = cpuData.data.filter(point =>
                point[0] >= startTime && point[0] <= endTime
            )

            series.push({
                name: cpuData.name,
                type: 'line',
                showSymbol: false,
                data: visibleData,
                itemStyle: {
                    color: colors[index]
                },
                emphasis: {
                    focus: 'series'
                },
                areaStyle: {
                    opacity: 0.1
                }
            })
            index++
        })

        console.log('Updating CPU chart with series:', {
            seriesCount: series.length,
            firstSeries: series[0]?.name
        })

        // 计算 Y 轴范围
        const allValues = series.flatMap(s => s.data.map((point: [number, number]) => point[1]))
        const min = Math.min(...allValues)
        const max = Math.max(...allValues)
        const range = max - min
        const padding = range * 0.1

        this.chart.setOption({
            yAxis: {
                min: Math.max(0, min - padding),
                max: max + padding
            },
            series: series
        })
    }

    dispose() {
        if (this.chart) {
            this.chart.dispose()
            this.chart = null
        }
        // 清理事件监听
        window.removeEventListener('resize', () => {
            this.chart?.resize()
        })
    }
}