import { useAuthStore } from "@/store/auth.store"
import axios, { AxiosError } from "axios"
import { useRouter } from "vue-router"
import { getApiBaseUrl } from '@/config/api.config'

type RunningProcess = {
    exe: string,
    pid: number,
    name: string,
    user: string|null,
    memory: number,
    status: string,
    command: string,
    run_time: number,
    cpu_usage: number,
}

export type SystemData = {
    id: number
    data: {
        cpu: Array<{
            brand: string,
            usage: number,
            frequency: number,
            vendor_id: string,
        }>,
        disks: Array<{
            name: string,
            used_space: number,
            mount_point: string,
            total_space: number,
            available_space: number,
            file_system_type: string,
        }>,
        memory: {
            used: number,
            total: number,
            available: number,
            used_swap: number,
            total_swap: number,
        },
        in_networks: Array<{
            mac_address: string,
            total_received: number,
            total_transmitted: number,
        }>,
        out_networks: Array<{
            mac_address: string,
            total_received: number,
            total_transmitted: number,
        }>,
        load_average: {
            one_minute: number,
            five_minutes: number,
            fifteen_minutes: number,
        },
        db_processlist: Array<{
            db: string,
            user: string,
            host: string,
            command: string,
            time: number,
            id: number,
            info: string|null,
            state: string|null,
        }>,
        base_system_info: {
            name: string,
            num_cpus: number,
            host_name: string,
            os_version: string,
            kernel_version: string,
        },
        max_cpu_processes: Array<RunningProcess>,
        max_time_processes: Array<RunningProcess>,
        max_memory_processes: Array<RunningProcess>,
    },
    host_name: string,
    created_at: string,
}

const baseUrl = getApiBaseUrl()

export const getIndexSystemData = async (): Promise<SystemData[]> => {
    const authStore = useAuthStore()
    if (!authStore.token) {
        throw new Error('No token')
    }
    try {
        const response = await axios.get(`${baseUrl}/system/last-data`, {
            headers: {
                Authorization: `Bearer ${authStore.token}`
            }
        })
        return response.data
    } catch (error) {
        const status =(error as AxiosError).response?.status ?? 500
        if (status === 401) {
            const router = useRouter()
            router.push('/login')
        }
        throw new Error(`Failed to fetch system data: ${status}`)
    }
}

export const getDetailSystemData = async (id: string, interval: string, startTime: string|null = null, endTime: string|null = null): Promise<SystemData[]> => {
    const authStore = useAuthStore()
    if (!authStore.token) {
        throw new Error('No token')
    }

    startTime = startTime ?? new Date().toISOString()
    endTime = endTime ?? new Date().toISOString()

    const response = await axios.get(`${baseUrl}/system/data`, {
        headers: {
            Authorization: `Bearer ${authStore.token}`
        },
        params: {
            start_time: startTime,
            end_time: endTime,
            host_name: id,
            interval,
        }
    })
    return response.data
}

export type DbProcess = {
    id: number,
    user: string,
    host: string,
    db: string,
    command: string,
    time: number,
    state: string|null,
    info: string|null,
}
export const getLastDbProcessList = async (host_name: string): Promise<DbProcess[]> => {
    const authStore = useAuthStore()
    if (!authStore.token) {
        throw new Error('No token')
    }

    try {
        const response = await axios.get(`${baseUrl}/system/db-processlist/last`, {
            headers: {
                Authorization: `Bearer ${authStore.token}`
            },
            params: {
                host_name,
            }
        })
        return response.data
    } catch (error) {
        throw new Error(`Failed to fetch system data: ${error}`)
    }
}