import axios, { AxiosError } from "axios"
import { getApiBaseUrl } from '@/config/api.config'

export type LoginRequest = {
    username: string
    password: string
}

type LoginResponse = {
    token: string
    exp: string
    username: string
}

const baseUrl = getApiBaseUrl()

export const login = async (request: LoginRequest): Promise<LoginResponse | number> => {
    try {
        const response = await axios.post<LoginResponse>(`${baseUrl}/sessions`, request)
        console.log(response);
        return response.data
    } catch (error) {
        console.error(error)
        return (error as AxiosError).response?.status ?? 500
    }
}
