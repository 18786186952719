import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center gap-4" }

import { getIndexSystemData, type SystemData } from '@/api/system-data.api'
import { ref, onMounted, computed } from 'vue'
import { useWindowSize } from '@vueuse/core'
import { NGrid, NGridItem, NCard, NSpace, NButton, NTooltip, NProgress } from 'naive-ui'
import router from "@/router";


export default /*@__PURE__*/_defineComponent({
  __name: 'IndexView',
  setup(__props) {

const systemData = ref<SystemData[]>([])
const { width } = useWindowSize()

// 根据窗口宽度计算每个卡片应该占据的列数
const deviceColumns = computed(() => {
    if (width.value < 640) return 24        // 小屏幕一行显示1个
    if (width.value < 1024) return 12       // 中等屏幕一行显示2个
    if (width.value < 1280) return 8        // 大屏幕一行显示3个
    return 6                                // 超大屏幕一行显示4个
})

const handleViewDetail = (item: SystemData) => {
    router.push(`/detail/${item.host_name}`)
}

const formatPercentage = (value: number, total: number) => {
    const percentage = value / total * 100
    return parseFloat(percentage.toFixed(2))
}

onMounted(async () => {
    const data = await getIndexSystemData()
    systemData.value = data
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(NGrid), {
    cols: 24,
    "x-gap": 16,
    "y-gap": 16
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(systemData.value, (item) => {
        return (_openBlock(), _createBlock(_unref(NGridItem), {
          span: deviceColumns.value,
          key: item.id
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(NCard), {
              title: item.host_name,
              hoverable: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(NSpace), { vertical: "" }, {
                  default: _withCtx(() => [
                    _cache[4] || (_cache[4] = _createElementVNode("span", null, "统计", -1)),
                    _createElementVNode("div", _hoisted_1, [
                      _createElementVNode("div", null, [
                        _cache[0] || (_cache[0] = _createElementVNode("h2", null, "Memory", -1)),
                        _createVNode(_unref(NTooltip), null, {
                          trigger: _withCtx(() => [
                            _createVNode(_unref(NProgress), {
                              type: "circle",
                              percentage: formatPercentage(item.data.memory.used, item.data.memory.total)
                            }, null, 8, ["percentage"])
                          ]),
                          default: _withCtx(() => [
                            _createTextVNode(" total: " + _toDisplayString((item.data.memory.total / 1024 / 1024).toFixed(2)) + " MB used: " + _toDisplayString((item.data.memory.used / 1024 / 1024).toFixed(2)) + " MB available: " + _toDisplayString((item.data.memory.available / 1024 / 1024).toFixed(2)) + " MB ", 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.data.disks, (disk) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: disk.name
                        }, [
                          _createElementVNode("h2", null, "Disk " + _toDisplayString(disk.name), 1),
                          _createVNode(_unref(NTooltip), null, {
                            trigger: _withCtx(() => [
                              _createVNode(_unref(NProgress), {
                                percentage: formatPercentage(disk.used_space, disk.total_space)
                              }, null, 8, ["percentage"])
                            ]),
                            default: _withCtx(() => [
                              _createTextVNode(" total: " + _toDisplayString((disk.total_space / 1024 / 1024 / 1024).toFixed(2)) + " GB used: " + _toDisplayString((disk.used_space / 1024 / 1024 / 1024).toFixed(2)) + " GB available: " + _toDisplayString((disk.available_space / 1024 / 1024 / 1024).toFixed(2)) + " GB mounted: " + _toDisplayString(disk.mount_point), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]))
                      }), 128)),
                      _createElementVNode("div", null, [
                        _cache[1] || (_cache[1] = _createElementVNode("h2", null, "Cpu", -1)),
                        _createElementVNode("p", null, "CPU数量: " + _toDisplayString(item.data.cpu.length), 1),
                        _createElementVNode("p", null, "CPU使用率超过50%的CPU数量: " + _toDisplayString(item.data.cpu.filter((cpu) => cpu.usage > 50).length), 1)
                      ]),
                      _createElementVNode("div", null, [
                        _cache[2] || (_cache[2] = _createElementVNode("h2", null, "数据库", -1)),
                        _createElementVNode("p", null, "进程数量: " + _toDisplayString(item.data.db_processlist.length), 1)
                      ])
                    ]),
                    _createVNode(_unref(NSpace), { justify: "end" }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(NButton), {
                          type: "primary",
                          onClick: ($event: any) => (handleViewDetail(item))
                        }, {
                          default: _withCtx(() => _cache[3] || (_cache[3] = [
                            _createTextVNode(" 查看详情 ")
                          ])),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["title"])
          ]),
          _: 2
        }, 1032, ["span"]))
      }), 128))
    ]),
    _: 1
  }))
}
}

})